<template>
  <div class="category-container">
    <div class="category-title">商品品类管理</div>
<!--    <div class="queryItems">-->
<!--      <el-button type="primary" class="mb10" size="small" icon="el-icon-plus" @click="addRow">添加</el-button>-->
<!--    </div>-->
    <el-table
        :data="listData"
        size="mini"
        border
        default-expand-all
        :height="tableHeight"
        :max-height="tableHeight"
        element-loading-text="获取数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
        v-loading="loading"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
              :data="props.row.children"
              size="mini"
              :show-header="false"
              border
              v-if="props.row.children.length"
          >
            <el-table-column label="名称" prop="Name" align="center"></el-table-column>
            <el-table-column label="编码" prop="Code" align="center"></el-table-column>
            <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="Name" align="center"></el-table-column>
      <el-table-column label="编码" prop="Code" align="center"></el-table-column>
<!--      <el-table-column label="货物类型" prop="GoodsTypeName" align="center"></el-table-column>-->
      <el-table-column label="备注" prop="Rem" align="center"></el-table-column>
<!--      <el-table-column-->
<!--          label="操作"-->
<!--          min-width="120px"-->
<!--          align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>-->
<!--          <el-button type="text" size="mini" @click="delRow(scope.row)">删除</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <el-dialog :title="textMap[dialogStatus]" width="25%" :visible.sync="visible">
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
        <el-form-item label="名称" prop="Name">
          <el-input v-model="ruleForm.Name" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item label="货物类型" prop="GoodsTypeId">
          <el-select style="width: 30rem" v-model="ruleForm.GoodsTypeId" placeholder="请选择货物类型">
            <el-option
                v-for="item in goodTypes"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="Rem">
          <el-input v-model="ruleForm.Rem" type="textarea" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button size="small" v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getGoodsType} from "@/api/system";
import { mapGetters } from "vuex"
import {addCODProduct, delCODProduct, getCODProduct, updateCODProduct} from "@/api/cod";
export default {
  data() {
    return {
      PageIndex: 1,
      visible: false,
      PageRows: 10,
      ruleForm: {},
      rules: {
        Name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        GoodsTypeId: [
          { required: true, message: '请输入货物类型', trigger: 'change' }
        ]
      },
      textMap: {
        create: "添加品类",
        update: "编辑品类"
      },
      dialogStatus: "",
      total: 0,
      listData: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(["goodTypes", "userInfo"]),
    tableHeight() {
      return 'calc(100vh - 17.6rem - 115px)'
    }
  },
  created() {
    this.getGoodsType()
    this.getData()
  },
  methods: {
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    arrayToTree(items) {
      const map = new Map(items.map(item => [item.Id, { ...item, children: [] }]));
      let roots = [];
      items.forEach(item => {
        const { ParentId, Id } = item;
        if (ParentId === 0) {
          roots.push(map.get(Id));
        } else {
          if (map.has(ParentId)) {
            map.get(ParentId).children.push(map.get(Id));
          }
        }
      });
      return roots;
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
      }
      this.loading = true
      getCODProduct(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = this.arrayToTree(Data)
          console.log(this.listData)
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    editRow(row) {
      this.ruleForm = Object.assign({}, row)
      this.dialogStatus = "update"
      this.visible = true
    },
    delRow(row) {
      this.$confirm(`该操作会永久删除名称为（${row.Name}）的数据，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      )
          .then(() => {
            const data = [row.Id]
            delCODProduct(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("删除成功！！")
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          })
          .catch(() => {
            this.$message.info("已取消删除")
          });
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
      })
    },
    addRow() {
      this.dialogStatus = "create"
      this.visible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === "create") {
            const data = {
              Name: this.ruleForm.Name,
              GoodsTypeId: this.ruleForm.GoodsTypeId,
              ClientId: this.userInfo.Id,
              Rem: this.ruleForm.Rem
            }
            addCODProduct(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("添加成功！！")
                this.visible = false
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          } else {
            const data = {
              Name: this.ruleForm.Name,
              GoodsTypeId: this.ruleForm.GoodsTypeId,
              ClientId: this.userInfo.Id,
              Rem: this.ruleForm.Rem,
              Id: this.ruleForm.Id
            }
            updateCODProduct(data).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("修改成功！！")
                this.visible = false
                this.getData()
              } else {
                this.$message.warning(Msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}

</script>

<style scoped lang="scss">
.category-container {
  padding: 20px;
  .category-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}
</style>